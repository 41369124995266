import { HeaderWithBackNavigation } from '@mfe/shared/components';
import {
  AddOnType,
  selectAddOns,
  setSelectedAddOn,
} from '@mfe/to-be-migrated/redux/addOns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Steps from './steps';
import styles from './styles.module.scss';

type HeaderProps = {
  navigateToPreviousPage: () => void;
  step: number;
  maxSteps: number;
  setStep: (step: number) => void;
};

const ShopAddOnHeader = ({
  navigateToPreviousPage,
  maxSteps,
  step,
}: HeaderProps): JSX.Element => {
  const { t } = useTranslation('ShopAddons');
  const { selectedAddOn } = useSelector(selectAddOns);
  const dispatch = useDispatch();

  const isVoice = selectedAddOn === AddOnType.Voice;

  const handleBackButton = () => {
    if (selectedAddOn) return dispatch(setSelectedAddOn(null));

    return navigateToPreviousPage();
  };

  return (
    <div
      className={styles['add-on-header-container']}
      data-cy="add-on-header-container"
    >
      <HeaderWithBackNavigation
        title={
          !selectedAddOn
            ? t('selectAddon.title')
            : t('selectedAddOn', { context: selectedAddOn })
        }
        handleBackNavigation={handleBackButton}
        goBackLabel={!selectedAddOn ? t('goBack') : t('allAddOns')}
        additionalClassName={`${styles['add-on-header-override']} ${
          isVoice && styles['add-on-voice-header']
        }`}
      />

      {isVoice && <Steps step={step} maxSteps={maxSteps} />}
    </div>
  );
};

export default ShopAddOnHeader;
