import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PageAlert, Txt, Button } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';

import {
  removeAlert,
  setBillingAlerts,
} from '@mfe/to-be-migrated/redux/alerts';
import {
  resetPayments,
  selectPayments,
  setPayments,
  retrieveTransactionId,
  PaymentMethodType,
} from '@mfe/to-be-migrated/redux/payments';
import {
  refetchBillingInfo,
  selectBillingInfo,
} from '@mfe/to-be-migrated/redux/billingInfo';
import { CardLogo } from '@mfe/shared/components';
import { useTrackTiming } from '@mfe/shared/util';
import { scrollToTop } from '@mfe/shared/redux/utils';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';
import { Locale, NotificationLevel, VppError } from '@mfe/shared/schema-types';

import {
  iframeProps,
  handleAutopayMessages,
  postMessageToVPS,
  getAutopayPageAlertTitle,
  getPageAlertDescription,
  parseVppEvent,
  VppEventCode,
  handleCommonVppMessages,
} from './utils';
import Card from '../shared-ui/card';
import Divider from '../shared-ui/divider';
import Gradient from '../shared-ui/gradient';
import { TitleText } from '../shared-ui/title';
import DifferentMethod from './different-method';
import { ANALYTICS_EVENT_NAME } from '../shared/constants';

import styles from './vpp.module.scss';
import sharedStyles from '../shared/styles.module.scss';

const INIT_AUTOPAY = JSON.stringify({
  initConnection: true,
  pageFunction: 'Wallet',
});

const AutoPay = () => {
  const {
    billingInfo: { autoPay },
  } = useSelector(selectBillingInfo);
  const config = useSelector(selectConfig);
  const {
    locale: { userLocale: locale },
  } = useSelector(selectLocale);
  const payments = useSelector(selectPayments);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { url, height, paymentMethodType, error, loading, frameReady } =
    payments;

  React.useLayoutEffect(() => {
    dispatch(resetPayments());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(retrieveTransactionId(undefined));
  }, [dispatch]);

  const showCurrentMethod = autoPay && locale === Locale.EnUs;

  const paymentDetails = React.useMemo(
    () => ({ payment_method_type: paymentMethodType }),
    [paymentMethodType]
  );

  const onCancel = React.useCallback(
    () => dispatch(setBillingNav({ route: Route.Home })),
    [dispatch]
  );

  const onSuccess = React.useCallback(() => {
    dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.updatePaymentMethodSuccess,
        version: '1-0-1',
        data: { paymentDetails },
      })
    );
    dispatch(refetchBillingInfo());
    dispatch(removeAlert(t('Alerts:Billing.NoValidPaymentMethod.title')));
    dispatch(removeAlert(t('Alerts:Billing.PaymentMethodExpired.title')));
    dispatch(
      setBillingAlerts([
        {
          level: NotificationLevel.Success,
          title: t('Alerts:Billing.PaymentMethodUpdated.title'),
          caption: t('Alerts:Billing.PaymentMethodUpdated.caption'),
        },
      ])
    );
    dispatch(setBillingNav({ route: Route.Home }));
    dispatch(scrollToTop());
  }, [dispatch, paymentDetails, t]);

  const onError = React.useCallback(
    (error?: VppError) => {
      const paymentDetailsFailure = {
        ...paymentDetails,
        failure_reason: error?.message ?? 'Update Failed.',
      };
      dispatch(
        trackBillingEvent({
          eventName: ANALYTICS_EVENT_NAME.updatePaymentMethodFailed,
          version: '1-0-1',
          data: paymentDetailsFailure,
        })
      );
      dispatch(setPayments({ error, submit: false }));
      dispatch(scrollToTop());
    },
    [dispatch, paymentDetails]
  );

  React.useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      const eventData = parseVppEvent(event, config.vppUrl);

      const handleVppMessage = [
        VppEventCode.PAYMENT_TYPE_CHANGED,
        VppEventCode.UPDATE_PMT_MTHD_CLICKED,
      ].includes(eventData?.eventCode as VppEventCode)
        ? handleAutopayMessages
        : handleCommonVppMessages;

      handleVppMessage(
        event,
        { ...payments, onSuccess, onError, onCancel },
        config.vppUrl,
        dispatch
      );
    };
    window.addEventListener('message', handleIframeMessage);

    return () => window.removeEventListener('message', handleIframeMessage);
  }, [payments, config, onSuccess, onError, onCancel, dispatch]);

  React.useEffect(() => {
    dispatch(
      setPayments({
        paymentMethodType: config.setACHasDefault
          ? PaymentMethodType.BANK
          : PaymentMethodType.CARD,
      })
    );
  }, [dispatch, config]);

  useTrackTiming({
    eventName: 'VPPUpdatePaymentMethodLoaded',
    isLoading: !frameReady,
  });

  return (
    <div className={styles['wrapper']}>
      <div className={styles['paymentContainer']}>
        {error && (
          <div className={sharedStyles['notificationsContainer']}>
            <PageAlert
              title={getAutopayPageAlertTitle(error, t)}
              variant={'error'}
              description={getPageAlertDescription(error, t)}
              closeable={true}
              onClose={() => {
                dispatch(setPayments({ error: null }));
                dispatch(retrieveTransactionId(undefined));
              }}
              className={sharedStyles['notification']}
            />
          </div>
        )}

        <Card className={styles['card']}>
          <Gradient />

          <div className={styles['container']}>
            <div
              className={styles['titleContainer']}
              data-cy="make-payment-page-title"
            >
              <Txt variant="heading4">{t('VPP:updatePaymentMethod')}</Txt>
            </div>

            {showCurrentMethod && (
              <>
                <div className={styles['statusContainer']}>
                  <TitleText
                    title={t('VPP:currentPaymentMethodTitle')}
                    style={{ paddingBottom: 12 }}
                  >
                    <div className={styles['label']}>
                      <CardLogo name={autoPay?.paymentMethod} width="44px" />
                      <Txt variant="bodySmallRegular" color="regular">
                        {t('VPP:paymentMethod', {
                          ...autoPay,
                          count: autoPay?.last4Digits?.length,
                        })}
                      </Txt>
                    </div>
                  </TitleText>
                </div>
                <div className={styles['statusContainer']}>
                  <div className={styles['terms']}>
                    <Txt variant="bodySmallRegular" color="regular">
                      {t('VPP:currentPaymentMethodTerms')}
                    </Txt>
                  </div>
                </div>
              </>
            )}
            <div className={styles['differentMethodContainer']}>
              {loading && (
                <DifferentMethod frameReady={frameReady}>
                  <iframe
                    title="Update Payment Method"
                    height={height}
                    src={url}
                    onLoad={() => postMessageToVPS(INIT_AUTOPAY, url)}
                    {...iframeProps}
                  />
                </DifferentMethod>
              )}
            </div>
            {paymentMethodType === PaymentMethodType.CARD && (
              <Txt
                variant="smallRegular"
                component="p"
                style={{ padding: '0px 48px 24px' }}
              >
                {t('VPP:preAuthMessage')}
              </Txt>
            )}
            {!frameReady && (
              <>
                <Divider />
                <div className={styles['footerContainer']}>
                  <AutoPayButtons onCancel={onCancel} />
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const AutoPayButtons = ({ onCancel }: { onCancel: () => void }) => {
  const { t } = useTranslation('VPP');

  return (
    <div className={styles['paymentButtonsContainer']}>
      <Button
        variant="secondary"
        className={styles['cancelButton']}
        onClick={onCancel}
      >
        <Txt variant="labelMedium">{t('cancelButton')}</Txt>
      </Button>

      <Button className={styles['paymentButton']} disabled={true}>
        <Txt variant="labelMedium">{t('updatePaymentMethod')}</Txt>
      </Button>
    </div>
  );
};

export default AutoPay;
