import { useHistory } from 'react-router';
import { ShopAddons } from '@mfe/features/add-ons';

const ShopAddonsPage = (): JSX.Element => {
  const history = useHistory();

  const navigateToPreviousPage = () => {
    history.goBack();
  };
  return <ShopAddons navigateToPreviousPage={navigateToPreviousPage} />;
};

export default ShopAddonsPage;
