import { Button, StandAloneLink } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type FooterCTAProps = {
  isMobileOrTablet: boolean;
  placeOrder: () => void;
};

export const FooterCTA = ({ isMobileOrTablet, placeOrder }: FooterCTAProps) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <div data-cy="addons-footer" className={styles['addons-footer']}>
      <Button
        onClick={() => {
          placeOrder();
        }}
        style={
          isMobileOrTablet
            ? { maxWidth: '400px', width: '100%', alignSelf: 'center' }
            : undefined
        }
      >
        {t('subscribeNow')}
      </Button>
    </div>
  );
};
