import { Button, StandAloneLink } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type VoiceCTAProps = {
  step: number;
  setStep: (step: number) => void;
  isMobileOrTablet: boolean;
  placeOrder: () => void;
  isMaxStep: boolean;
};

export const VoiceFooterCTA = ({
  isMobileOrTablet,
  setStep,
  step,
  placeOrder,
  isMaxStep,
}: VoiceCTAProps) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <div
      data-cy="addons-voice-footer"
      className={`${styles['addons-footer']} ${
        isMaxStep ? styles['step-2'] : ''
      }`}
    >
      {isMaxStep && (
        <StandAloneLink
          data-cy="back-to-voice-link"
          href=""
          onClick={(e) => {
            e.preventDefault();
            setStep(step - 1);
          }}
        >
          {t('backToVoice')}
        </StandAloneLink>
      )}

      <Button
        onClick={() => {
          if (isMaxStep) {
            placeOrder();
          } else {
            setStep(step + 1);
          }
        }}
        style={
          isMobileOrTablet
            ? { maxWidth: '400px', width: '100%', alignSelf: 'center' }
            : undefined
        }
      >
        {isMaxStep ? t('subscribeNow') : t('addAndConfigure')}
      </Button>
    </div>
  );
};
