import styles from './index.module.scss';
import Header from '../components/header';
import { useState } from 'react';
import { Surface, Txt } from '@vst/beam';
import { AddOnType, selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { useSelector } from 'react-redux';
import { useScreenResolution } from '@mfe/shared/util';
import { VoiceFooterCTA, FooterCTA } from '../components/footer';

type ShopAddonsProps = {
  navigateToPreviousPage: () => void;
};

const MAX_STEP = 2;

export const ShopAddons = ({ navigateToPreviousPage }: ShopAddonsProps) => {
  const { selectedAddOn } = useSelector(selectAddOns);
  const [step, setStep] = useState(1);
  const { isSmall, isExtraSmall, isMedium } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const isMobileOrTablet = isMobile || isMedium;
  const isVoice = selectedAddOn === AddOnType.Voice;

  return (
    <div className={styles['layout']}>
      <Header
        navigateToPreviousPage={navigateToPreviousPage}
        maxSteps={MAX_STEP}
        step={step}
        setStep={setStep}
      />

      {/* TODO: Remove the code below it is a placeholder for moving through the voice steps */}
      <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
        <Txt p="24px">Placeholder content to show button below</Txt>
        {selectedAddOn && (
          <>
            {isVoice ? (
              <VoiceFooterCTA
                setStep={setStep}
                isMobileOrTablet={isMobileOrTablet}
                step={step}
                placeOrder={() => console.log('Place order')}
                isMaxStep={step === MAX_STEP}
              />
            ) : (
              <FooterCTA
                isMobileOrTablet={isMobileOrTablet}
                placeOrder={() => console.log('Place order')}
              />
            )}
          </>
        )}
      </Surface>
    </div>
  );
};
